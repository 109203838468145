@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.warning-icon {
  width: 65px;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  color: var(--quaternary-text-color);
  margin-top: 1rem;
}
