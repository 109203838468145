@import 'libs/styles/src/common';

.container {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--blue-900);
  color: var(--color-white);
  position: fixed;
  bottom: 0;
  font-size: 12px;
  &.sidebar {
   padding-left:1rem;
   padding-right: 14rem;
  }
}
