@import 'libs/styles/src/common';

.container {
  max-width: 1500px;
  margin: auto;
  padding: 45px;
  @include media-breakpoint-down(md) {
    padding: 20px;
  }
}

.table-container {
  overflow-x: scroll;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: var(--primary-box-shadow);
  margin: 1.25rem 0;

  &__details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__contest {
    width: 100%;
    display: flex;
    padding-left: 53px;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
  }

  svg {
    width: 40px;
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;
    font-weight: 500;
  }
  &__nominate {
    color: var(--success-text);
    font-weight: 500;
    &__started {
      color: var(--success-text);
    }
    &__upcoming {
      color: var(--orange-500)
    }
    &__ended {
      color: var(--error-color)
    }
  }
  &__contests {
    color: var(--primary-color);
  }
}

.contests {
  max-width: 700px;
  margin: 3rem auto;
  padding: 3rem;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: var(--primary-box-shadow);

  &__buttons {
    width: 100%;
    display: flex;
    margin-top: 1.25rem;
    justify-content: flex-end;
  }
}

.contest-list {
  width: 100%;
  display: flex;
  background-color: var(--color-white);
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: var(--primary-box-shadow);
  margin-top: 1.25rem;

  &__details {
    display: flex;
    flex-direction: column;
  }
  &__name {
    font-size: 1.5rem;
    font-weight: 400;
  }
  &__email {
    font-size: 1rem;
  }
  &__nominated {
    background-color: var(--primary-color);
    color: var(--color-white);
    padding: 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    cursor: pointer;
  }
  &__denominated {
    display: flex;
    align-items: center;
    background-color: var(--accent-color);
    color: var(--color-white);
    padding: 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}

.ballot-name {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: 0.875rem;
    color: var(--light-text);
  }

  &__value {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
