@import 'libs/styles/src/common';

.container {
  height: fit-content;
  width: 100%;
  border-radius: 0.75rem;
  background-color: var(--background-grey);
  padding: 15px 86px 10px 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;

  @include media-breakpoint-down(sm) {
    height: auto;
    margin-top: 0;
    align-items: flex-start;
    padding: 13px 23px 25px 10px;
  }
}

.hand-icon {
  flex-shrink: 0;
}

.text {
  margin-left: 1rem;
  font-size: 1rem;
  color: var(--quaternary-text-color);
  font-weight: 500;
}
