@import 'libs/styles/src/common';

.container {
  border-top: 1px solid var(--light-border);
  padding: 1.25rem 1.25rem 0;
  margin-bottom: 1.563rem;
  text-align: center;
  margin-top: auto;
}

.time {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

.logout-btn {
  margin-top: 1.438rem;
  color: var(--primary-color);
  font-size: 0.938rem;
  font-weight: 500;
  background-color: var(--color-white);
  width: 100%;
  border-radius: 0.375rem;
  height: 2.625rem;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}
