@import 'libs/styles/src/common';

.container {
  position: relative;
  cursor: pointer;
}

.select {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;

  &__selected-lang {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0 0.75rem;
    color: var(--quaternary-text-color);
  }

  &__chevron {
    width: 10px;
    height: 5px;
    background: var(--color-black);
    border-radius: 0 0 5px 5px;
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
    transition: transform 0.2s linear;

    &--rotate {
      transform: rotate(180deg);
    }
  }
}

.options {
  position: absolute;
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px -2px var(--box-shadow-color);

  &-item {
    padding: 0.25rem 1rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &__label {
      color: var(--quaternary-text-color);
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}
