@import 'libs/styles/src/common';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 300px;
  margin: auto;
  background-color: var(--color-white);
  border-radius: 0.75rem;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &__icon {
    width: 69.72px;
    height: 70px;
  }

  &__txt {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 37px;
  }
}
