@import 'libs/styles/src/common';

.container {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
}

.title {
  color: var(--tertiary-text-color);
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.content {
  font-weight: 700;
  color: var(--primary-color);

  &__value {
    font-weight: 400;
  }
}
