@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.progress__container {
  display: flex;
}

.illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.message p {
  font-size: 1.5rem;
  color: var(--tertiary-text-color);
}

.connector {
  display: flex;
  align-items: center;
  margin: 0 15px;

  .dot {
    width: 10px;
    height: 10px;
    background-color: #9e9e9e;
    border-radius: 50%;
    margin: 0 5px;
    animation: progress-animation 1.5s infinite;

    &:nth-child(1) {
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      animation-delay: 0.7s;
    }

    &:nth-child(3) {
      animation-delay: 0.9s;
    }

    &:nth-child(4) {
      animation-delay: 1.1s;
    }
  }

  @keyframes progress-animation {
    0%,
    100% {
      background-color: var(--background-light-grey);
    }

    50% {
      background-color: var(--success-color);
    }
  }
}
