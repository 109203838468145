@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
}

.error__text {
  color: var(--error-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 1.25rem;
  white-space: pre-line;
}

.error__icon {
  width: 60px;
}
