@import 'libs/styles/src/common';

.container {
  height: 45px;
  display: flex;
  flex-flow: row;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-left: 10px;
  border: 2px solid var(--accent-color);
  border-radius: 6px;
  color: var(--quaternary-text-color);
  background: var(--color-white);
  font-size: 1rem;
  font-weight: 400;

  &:focus-within {
    outline: none;
    border: 2px solid var(--color-black);
    box-shadow: var(--highlighted-box-shadow);
  }

  &::placeholder {
    color: var(--accent-color);
  }
}

.search {
  border: none;
  outline: none;
  width: 100%;
  border-radius: 6px;
}
