@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
}

.dropdown__container {
  display: flex;
  flex-direction: column;

  select {
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    border-radius: 6px;
    border: 1px solid var(--color-black);
    color: var(--quaternary-text-color);
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
  }

  .error {
    color: var(--error-color);
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
}
