@import 'libs/styles/src/common';

.container {
  border-bottom: 1px solid var(--light-border);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.25rem;
}

.label {
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.button {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
