@import 'libs/styles/src/common';

.date-picker {
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 2px;

  .label {
    font-size: 1rem;

    &.required::after {
      content: '*';
      color: var(--error-color);
      margin-left: 4px;
    }
  }

  input {
    padding-left: 15px;
    width: 100%;
    border: 1px solid var(--color-black);
    height: 45px;
    border-radius: 6px;
    font-size: 1rem;
    color: var(--quaternary-text-color);

    &:focus {
      outline: none;
      border: 2px solid var(--color-black);
      box-shadow: var(--highlighted-box-shadow);
    }

    font-size: 1rem;
    font-weight: 400;

    &::placeholder {
      color: var(--accent-color);
    }
  }

}
