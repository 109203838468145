@import 'libs/styles/src/common';

.textarea__container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-size: 1rem;

  &.required::after {
    content: '*';
    color: var(--error-color);
    margin-left: 4px;
  }
}

.TextAreaField {
  height: 150px;
  padding: 15px;
  border-radius: 8px;
  border: none;
  width: 100%;
  color: var(--quaternary-text-color);
  border-radius: 6px;
  border: 1px solid var(--color-black);

  &:focus {
    outline: none;
    border: 2px solid var(--color-black);
    box-shadow: var(--highlighted-box-shadow);
  }

  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: var(--accent-color);
  }
}

.max-chars{
  display: flex;
  justify-content: flex-end;
  font-size: 0.85rem;
  width: 100%;
  color: var(--success-text);
  margin-top: 0.5rem;
  &__limit-reached{
    color: var(--error-color);
  }
}
