@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed var(--primary-color);
  background-color: var(--tertiary-color);
  border-radius: 0.75rem;
  margin: 30px;
  height: 300px;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    height: 250px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.text {
  color: var(--quaternary-text-color);
  margin-top: 10px;
  margin-bottom: 21px;
}

.subtext {
  color: var(--tertiary-text-color);
  font-weight: 500;
  text-align: center;
}
