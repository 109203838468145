@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.description {
  max-width: 70%;
  text-align: center;
  font-size: 1.5rem;
}
