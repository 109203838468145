@import 'libs/styles/src/common';

.container {
  display: none;
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 1000;
  background: white;
  bottom: 0;
  justify-content: space-around;
  align-items: center;
  box-shadow: var(--primary-box-shadow);

  @include media-breakpoint-down(md) {
    display: flex;
  }
}

.tab {
  display: flex;
  padding-top: 5px;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &-active {
    background-color: var(--tertiary-color);
  }
}
