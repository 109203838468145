@import 'libs/styles/src/common';

.container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.063rem;
  background-color: var(--color-white);
  z-index: 2;
  box-shadow: var(--primary-box-shadow);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;

  @include media-breakpoint-down(md) {
    padding: 0 0.313rem;
  }
}

.logo {
  display: none;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    margin-left: 0.313rem;
    display: block;
  }
}

.hamburger {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
  }
}

.logout-timer {
  margin: auto;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.logout-txt {
  font-weight: 600;
  color: var(--secondary-color);

  &:hover {
    opacity: 0.8;
  }
}

.divider-container {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}


/* PROJECT SPECIFIC STYLES */
.queensland {
  &.container {
    background-color: var(--blue-900);
    color: var(--color-white);
  }
  span {
    color: var(--color-white);
  }
  button {
    color: var(--color-white);
  }
  .hamburger {
    span {
      background-color: var(--color-white);
    }
  }
}
/* end PROJECT SPECIFIC STYLES */
