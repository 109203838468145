@import 'libs/styles/src/common';

.container {
  width: 800px;

  @include media-breakpoint-down(md) {
    width: 525px;
  }

  @include media-breakpoint-down(xs) {
    width: 400px;
  }
}
