@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
}

.inputs__container {
  display: flex;
  flex-flow: column;
  padding: 1rem;
}

.phone__input {
  width: 100%;
  display: flex;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid var(--color-black);
  padding: 0.5rem;
  border-radius: 6px;
  transition: border-color 0.3s ease;

  &:focus-within {
    border-color: #007bff;
    box-shadow: 0 0 5px rgb(0 123 255 / 50%);
  }

  input {
    border: none;
    outline: none;
  }
}
