@import 'libs/styles/src/common';

.container {
  margin-top: 30px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.default-btn {
  display: flex;
  align-items: flex-start;

  &-toggle {
    border: 1px solid var(--color-black);
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.2px;
  }

  &__label {
    margin-left: 15px;
    font-size: 14px;
  }
}

.circle-btn {
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &__toggle {
    position: relative;
    min-width: 1.5rem;
    min-height: 1.5rem;
    background-color: var(--color-white);
    border-radius: 50%;
    border: 3px solid var(--color-black);

    &--active::after {
      content: '';
      height: 0.75rem;
      width: 0.75rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--primary-color);
      border-radius: 50%;
    }
  }

  &__label {
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-black);
    margin-left: 10px;
  }
}
