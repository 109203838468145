@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session {
  margin-top: 20px;

  &__time {
    display: flex;
    flex-flow: column;
    text-align: center;
  }

  &__label {
    font-size: 1rem;
    font-weight: 400;
    color: var(--tertiary-text-color);
  }

  &__value {
    font-weight: 700;
    font-size: 1rem;
    color: var(--primary-color);
  }
}

.text {
  font-weight: 500;
  color: var(--quaternary-text-color);
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
}

