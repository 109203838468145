@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--quaternary-text-color);
  text-align: center;
}

.img {
  width: 76px;
  height: 76px;
}

.label {
  font-weight: 500;
  font-size: 32px;
  color: var(--success-color);
  margin-top: 18px;
  margin-bottom: 18px;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--quaternary-text-color);
  text-align: center;
}
