@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 11px;
}

.icon {
  margin-right: 11px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.text {
  font-size: 14px;
  font-weight: 400;

  &--valid {
    color: var(--success-color);
  }

  &--invalid {
    color: var(--error-color);
  }
}
