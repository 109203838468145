@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: row;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.label {
  font-size: 1rem;
  font-weight: 400;
}

.time {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
