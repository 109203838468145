@import 'libs/styles/src/common';

.container {
  padding: 75px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.icon {
  margin-bottom: 20px;
  width: 65px;
}

.text {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--quaternary-text-color);
  text-align: center;
}
