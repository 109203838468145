@import 'libs/styles/src/common';

.select {
  width: 100%;
}

.container {
  display: flex;
  flex-flow: column;
}

.label {
  margin-bottom: 5px;
}
