@import 'libs/styles/src/common';

.dropdown {
  cursor: pointer;
  position: relative;
}

.select {
  cursor: pointer;
}

.options {
  display: flex;
  flex-flow: column;
  background-color: var(--color-white);
  border: 0.063rem solid var(--tertiary-color);
  box-shadow: var(--primary-box-shadow);

  &--table-inline {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 140px;
    margin-left: -9rem;
    z-index: 2;
  }

  &--help-n-support {
    position: absolute;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--quaternary-text-color);
}

.option {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0.625rem;

  &:hover {
    background-color: var(--tertiary-color);
    border-radius: 0.25rem;
    color: var(--primary-color);
    cursor: pointer;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--quaternary-text-color);
    margin-left: 0.5rem;
  }

  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
}

