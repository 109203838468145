@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  font-weight: 500;
}
