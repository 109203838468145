@import 'libs/styles/src/common';

.container {
  display: flex;
  width: 100%;
  padding-left: 2.5rem;
  border-bottom: 0.1rem solid var(--light-border);
  background-color: var(--background-grey);

  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
}
.wrapper {
  display: flex;
  overflow-x: scroll;
}

.button {
  width: 180px;
  display: flex;
  height: 54px; /* This is to match the sidebar breakline with tabs */
  justify-content: center;
  padding: 0 1rem;
  align-items: center;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
  border-bottom: 5px solid transparent;
  transition: all 0.3s ease;

  &.active {
    border-bottom: 5px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
    @include media-breakpoint-down(md) {
      border-bottom: unset;
      border-top: 1px solid var(--primary-color);
      border-right: 1px solid var(--primary-color);
      border-left: 1px solid var(--primary-color);
      background: var(--upload-file);
    }
  }
}
