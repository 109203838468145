@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: column;
  padding: 70px;
  padding-left: 270px;
  margin-top: 100px;

  @include media-breakpoint-down(sm) {
    margin-top: 120px;
    display: flex;
    flex-flow: column;
    padding-left: 10px;
  }
}
