@import 'libs/styles/src/common';

.tab {
  width: 100%;
  display: flex;
  position: relative;
  padding: 1.25rem;
  overflow: hidden;
  background-color: var(--color-white);
  white-space: nowrap;
  border: none;
  text-align: left;
  transition: opacity 0.2s linear;
  cursor: pointer;
  justify-content: center;

  &:hover:not(.tab-active)::before {
    width: 100%;
  }

  &:hover:not(.tab-active) {
    opacity: 0.8;
  }
}

.tab-active {
  background-color: var(--tertiary-color);
  font-weight: bold;
}

.tab-active-text,
.tab-inactive-text {
  margin-left: 0.313rem;
  font-size: 1rem;
  flex: 1 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--primary-color);
}

.tab-inactive-text {
  font-weight: 400;
  color: var(--quaternary-text-color);
}
