@import 'libs/styles/src/common';

.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  width: 13rem;
  background-color: var(--color-white);
  box-shadow: 0 0 10px var(--box-shadow-color);
  z-index: 3;
  transition: width 0.3s;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &__collapsed {
    width: 80px;
    align-items: center;

    &__logo {
      width: 3.75rem;
      margin: auto;
    }
  }

  &__is-mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__is-not-mobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    height: 119px;
    align-items: center;
    flex-shrink: 0;
  }

  &__logo {
    width: 100%;

    @include media-breakpoint-down(md) {
      width: 11rem;
      padding: 0;
    }
  }

  &__x-icon {
    display: none;
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__divider {
    width: 100%;
    border-top: 0.1rem solid var(--light-border);
    opacity: 1;
  }

  &__collapse-btn {
    position: absolute;
    bottom: 1.5rem;
    right: -1.7rem;
    border: none;
    background: none;
    opacity: 0.5;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.dropdown-languages {
  margin-left: 1.25rem;
  margin-top: 1.875rem;
}
