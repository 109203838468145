@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.label {
  font-size: 1rem;

  &.required::after {
    content: '*';
    color: var(--error-color);
    margin-left: 4px;
  }
}

.input__container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.input {
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  border-radius: 6px;
  border: 1px solid var(--color-black);
  color: var(--quaternary-text-color);

  &:focus {
    outline: none;
    border: 2px solid var(--color-black);
    box-shadow: var(--highlighted-box-shadow);
  }

  font-size: 1rem;
  font-weight: 400;

  &::placeholder {
    color: var(--accent-color);
  }
}

.error {
  color: var(--error-color);
  margin-left: 4px;
}
