@import 'libs/styles/src/common';

.container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;


  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    margin: 1em 0;
    box-shadow: var(--primary-box-shadow);
  }

  .react-pdf__message {
    padding: 1.25rem;
    color: white;
  }
}
