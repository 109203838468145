@import 'libs/styles/src/common';

.table-container {
  min-width: 1200px;
  padding: 1rem;
  background-color: var(--color-white);
  border-radius: 8px;
  min-height: 400px;
}

.actionbar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    justify-content: unset;
  }

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}




.table {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 0.75rem;
  padding: 20px;
  border-collapse: collapse;
  overflow-x: scroll;

  @include media-breakpoint-down(md) {
    padding: 5px;
  }

  thead {
    height: 50px;
    font-size: 1rem;
    color: var(--tertiary-text-color);
    background-color: var(--background-grey);
    text-align: left;
    border-radius: 10px;
    border-spacing: 0;
  }

  tr {
    margin: 10px 0;
    border-bottom: 1px dashed #e4e6ef;
    font-size: 0.875rem;
    font-weight: 500;
  }

  th {
    padding: 10px;
    color: var(--tertiary-text-color);
    font-weight: 500;
    cursor: pointer;
  }

  td {
    padding: 1rem 0;
    font-size: 0.65rem;
    text-align: left;
    position: relative;
  }

  .sort-asc {
    margin-top: 10px;
    margin-left: 5px;
  }

  .sort-desc {
    transform: rotate(180deg);
    margin-top: 10px;
    margin-left: 5px;
    position: absolute;
  }

  th:first-child, & > td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  th:last-child, & > td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.expand-btn {
  background: var(--color-white);
  border: none;
  margin-top: 2px;
  cursor: pointer;

  &__expanded {
    transform: rotate(90deg)
  }

}

.expanded__content {
  background-color: var(--background-grey);
}
