@import 'libs/styles/src/common';

.primary-btn {
  background-color: var(--primary-color);
}

.cancel-btn {
  background-color: var(--tertiary-text-color);
  margin-right: 20px;
}

.primary-btn,
.cancel-btn {
  height: 47px;
  width: fit-content;
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  border: none;
  color: var(--color-white);
  font-weight: 600;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

