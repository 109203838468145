@import 'libs/styles/src/global';

@font-face {
  font-family: 'proximanova';
  src: url('/proximanova.ttf') format('opentype');
}

body {
  font-family: 'proximanova';
}

.main-content-container {
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

:root:root {
  --primary-color: #124C7B;
  //project specific colors
  --upload-file: #f1faff;
  --orange-500: #e46d00;
  --blue-900: #012A4C;
}
