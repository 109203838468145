@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: column;
  margin-top: 1.5rem;
}

.title {
  font-size: 14px;
  font-weight: 400;

  &--valid {
    color: var(--success-color);
  }

  &--invalid {
    color: var(--error-color);
  }
}
