@import 'libs/styles/src/common';

.container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: 500;
  font-size: 0.65rem;
  color: var(--tertiary-text-color);
}

.value {
  color: var(--primary-color);
  font-size: 0.825rem;
  padding: 0 10px;
}

.prev__chevron,
.next__chevron {
  scale: 0.7;
  margin: auto 20px;
  cursor: pointer;
}

.next__chevron {
  transform: rotate(-90deg);
}

.prev__chevron {
  transform: rotate(90deg);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.prev__chevron a,
.next__chevron a {
  display: flex;
  justify-content: center;
}

.pagination-container {
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(md) {
    justify-content: left;
  }

  li::marker {
    content: '';
  }

  .pagination-item {
    border-radius: 6px;
    a {
      width: 32px;
      height: 32px;
      margin: auto 4px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      font-weight: 500;
      font-size: 0.75rem;
      justify-content: center;

    }
    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: var(--background-light-grey);
      cursor: pointer;
    }

    &.selected {
      justify-content: center;
      background-color: var(--primary-color);
      color: var(--color-white);
    }

    .arrow {
      &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 4px solid var(--color-black);
        border-top: 4px solid var(--color-black);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid var(--quaternary-text-color);
        border-top: 0.12em solid var(--quaternary-text-color);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
