@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--upload-file);
  border-radius: 8px;
  align-items: center;
  padding: 5rem 0;
  border: 1px dashed var(--primary-color);
  margin-top: 2rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &.dragging {
    background-color: var(--tertiary-color);
    border: 1px solid var(--primary-color);
    box-shadow: inset 0 0 5px var(--box-shadow-color);
  }

  @include media-breakpoint-down(md) {
    padding: 5px;
  }

  svg {
    margin-bottom: 3rem;
    width: 50px;
    height: 50px;
  }

  button {
    margin-top: 1rem;
  }
}

.text {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
}

