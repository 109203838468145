@import 'libs/styles/src/common';

.btn {
  width: fit-content;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 0.375rem;
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-white);
  border: none;
  background-color: var(--primary-color);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &--action {
    background-color: var(--primary-color);
  }

  &--primary {
    background-color: var(--color-white);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  &--primary-grey {
    background-color: var(--color-white);
    color: var(--tertiary-text-color);
    border: 1px solid var(--tertiary-text-color);
  }

  &--cancel {
    background-color: var(--tertiary-text-color);
    color: var(--color-white);
    margin-right: 1.563rem;
  }

  &--disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
