@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.time {
  display: flex;
  flex-flow: row;
  align-items: baseline;
}

.title {
  font-size: 1rem;
  font-weight: 400;
  color: var(--tertiary-text-color);
}

.time__minutes-value,
.time__seconds-value {
  font-weight: 700;
  font-size: 1rem;
  color: var(--primary-color);
}

.time__minutes-label,
.time__seconds-label {
  font-size: 1rem;
  color: var(--primary-color);
}

.time__seconds-value {
  margin-left: 0.3rem;
}
