@import 'libs/styles/src/common';

.container {
  width: calc(25% - 10px);
  background-color: var(--color-white);
  box-shadow: var(--primary-box-shadow);

  @include media-breakpoint-down(lg) {
    width: calc(33.33% - 10px);
  }

  @include media-breakpoint-down(md) {
    width: calc(50% - 10px);
  }

  @include media-breakpoint-down(xs) {
    width: calc(100% - 10px);
  }
}

.body {
  padding: 1.25rem;
}

.body__txt {
  font-size: 1.25rem;
  color: var(--quaternary-text-color);
  font-weight: 600;
}

.footer {
  padding: 1.25rem;
  border-top: 2px solid var(--light-border);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
