@import 'libs/styles/src/common';

.input {
  display: flex;
  margin-top: 0.5rem;
}

.input > input {
  height: 45px;
  background: var(--color-white);
  padding-left: 15px;
  border-radius: 6px;
  border: 1px solid var(--color-black);
  width: 100%;
  color: var(--quaternary-text-color);

  &:focus {
    outline: none;
    border: 2px solid var(--color-black);
    box-shadow: var(--highlighted-box-shadow);
  }

  font-size: 1rem;
  font-weight: 400;

  &::placeholder {
    color: var(--accent-color);
  }
}
