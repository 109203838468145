@import 'libs/styles/src/common';

.container {
  &--column {
    display: flex;
    flex-flow: column;
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.content {
  display: flex;
  align-items: center;
  text-transform: 'capitalize';
}

.divider {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.625rem;

  @include media-breakpoint-down(sm) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.title,
.time-card__value {
  font-size: 1.1rem;
  font-weight: 700;
}

.title {
  padding-bottom: 0.5rem;
  font-weight: 600;
  color: var(--quaternary-text-color);

  @include media-breakpoint-down(md) {
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.time-card {
  display: flex;
  flex-flow: column;
}

.time-card__label {
  font-size: 0.5rem;
  color: var(--tertiary-text-color);
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.2rem;
}

.time-card__value {
  width: 54.32px;
  height: 54.32px;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--primary-box-shadow);
}

.time-card__value__txt {
  color: var(--primary-color);
}
