@import 'libs/styles/src/common';

.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
}

.btn {
  height: 50px;
  width: 100%;
  border: 1px solid var(--color-black);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.text {
  margin-left: 14px;
  font-weight: 500;
  font-size: 14px;
}

.icon {
  display: flex;
  flex-flow: row;
  align-items: center;

  svg {
    width: 30px;
  }
}
