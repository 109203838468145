@import 'libs/styles/src/common';

.text {
  font-weight: 600;
  font-size: 1.125rem;

    &--success {
    color: var(--success-color);
  }

    &--warning {
    color: var(--orange-500);
  }

    &--error {
    color: var(--error-color);
  }
}
