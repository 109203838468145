@import 'libs/styles/src/common';

.hamburger__icon {
  cursor: pointer;
  width: 50px;
  height: 40px;
  position: relative;
  transition: 0.5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 8px;
    width: 100%;
    background: var(--secondary-color);
    border-radius: 8px;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 15px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 30px;
      transform-origin: left center;
    }
  }

  &.open {
    span:nth-child(1) {
      transform: rotate(45deg);
      top: 0;
      left: 8px;
    }

    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 36px;
      left: 8px;
    }
  }

  &__sidebar-container {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
