@import 'libs/styles/src/common';

.container {
  margin-top: 1.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text {
  font-size: 18px;
  color: var(--dark-text);
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}
