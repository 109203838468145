@import 'libs/styles/src/common';

.container {
  margin-top: 1.25rem;
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.upload {
  height: 87px;

  &:hover {
    cursor: auto;
    opacity: 1;
  }

  &__icon {
    display: flex;
    flex-flow: row;
    align-items: center;

    svg {
      width: 30px;
    }
  }

  &__text {
    margin-left: 14px;
    font-weight: 500;
    font-size: 14px;
  }

}
