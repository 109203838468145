@import 'libs/styles/src/common';

.unsupported-file {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unsupported-file__txt {
  font-size: 1rem;
  color: var(--quaternary-text-color);
}
