@import 'libs/styles/src/common';

.progress {
  width: 100%;
  background-color: var(--background-grey);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: 30px;
  margin: 1rem 0;
  box-shadow: inset var(--primary-box-shadow);

  &__bar {
    height: 100%;
    background-color: var(--success-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-weight: bold;
    font-size: 1rem;
    transition: width 0.5s ease-in-out;
  }

  &__percentage {
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--color-white);
    font-size: 1rem;
  }
}
