@import 'libs/styles/src/common';

.container {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 2px;
  position: relative;
}

.select {
  cursor: pointer;
  display: flex;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #000;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.label {
  font-style: normal;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-black);
}

.required {
  color: var(--error-color);
}

.options {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-flow: column;
  background-color: var(--color-white);
  border: 0.063rem solid var(--tertiary-color);
  width: 100%;
  box-shadow: var(--primary-box-shadow);
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 66px;
}

.option {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0.625rem;

  &:hover {
    background-color: var(--tertiary-color);
    border-radius: 0.25rem;
    color: var(--primary-color);
    cursor: pointer;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--quaternary-text-color);
    margin-left: 0.5rem;
  }
}
.placeholder {
  color: var(--accent-color);
}

.chevron {
  transition: 0.3s;
  transform: rotate(0deg);
}

.chevron-flip {
  transition: 0.3s;
  transform: rotate(180deg);
}
