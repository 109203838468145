@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-border);
  padding: 1rem;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.mobile-container {
  display: none;
  justify-content: center;
  padding: 1rem;

  @include media-breakpoint-down(lg) {
    display: flex;
  }
}
