@import 'libs/styles/src/common';

.lg-date {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-flow: column;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.lg-date__label,
.lg-date__value {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 18px;
  color: var(--tertiary-text-color);

  @include media-breakpoint-down(lg) {
    margin-right: 0;
    text-align: center;
    margin-bottom: 21px;
    margin-top: 37px;
  }
}

.default-label {
  font-weight: 400;
  font-size: 14px;
  color: var(--tertiary-text-color);
}

.default-value {
  margin-top: 5px;
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}
