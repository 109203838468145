@import 'libs/styles/src/common';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.625rem;
  height: 4.063rem;
  box-shadow: var(--primary-box-shadow);
  z-index: 1;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  cursor: pointer;
}

/* PROJECT SPECIFIC STYLES */
.queensland {
  &.container {
    background-color: var(--blue-900);
    color: var(--color-white);
  }
  span {
    color: var(--color-white);
  }
  button {
    color: var(--color-white);
  }
  .hamburger {
    span {
      background-color: var(--color-white);
    }
  }
}
/* end PROJECT SPECIFIC STYLES */
