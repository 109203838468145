@import 'common';
@import 'fonts';

* {
  box-sizing: border-box;
}

body {
  background-color: var(--background-grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

button {
  font-family: Poppins, Helvetica, sans-serif;
}

#root {
  display: flex;
}

::-webkit-scrollbar {width: 8px; height: 8px;}
::-webkit-scrollbar-thumb {background-color: var(--primary-color); border-radius: 10px;border: 2px solid var(--color-white);}
::-webkit-scrollbar-track {background-color: var(--background-grey);border-radius: 10px;}
::-webkit-scrollbar-button {display: none;}


:root {
  --primary-color: #1162a5;
  --secondary-color: #3699ff;
  --tertiary-color: #e7f5fd;
  --accent-color: #a1a5b7;
  --color-white: #fff;
  --color-black: #000;

   //Background
   --background-grey: #f2f3f7;
   --background-light-grey: #e1e1ee;

  //Text
  --light-text: var(--accent-color);
  --dark-text: #181c32; //was --quinary-text-color


  // tertiary-text-color and quaternary-text-color are too similar, normalize them
  // ex.--header-text: #3f4254;
  // ex.--link-text: var(--primary-color);
  --tertiary-text-color: #5e6278;
  --quaternary-text-color: #3f4254;


  //svgs
  --svg-light-fill: #dadada;
  --svg-dark-fill: var(--accent-color);
  --svg-darker-fill: var(--tertiary-text-color);

  //Borders
  --border-color: var(--accent-color);
  --light-border: var(--background-light-grey);
  --dark-border: var(--accent-color);
  --box-shadow-color: #2424241a;
  --primary-box-shadow: 0 0 10px var(--box-shadow-color);
  --highlighted-box-shadow: 0 0 7px 3px #00818f40;

    //Errors
    --error-color: #d20033;
    --warning-color: #fff8dd;
    --success-color: #26a660;
    --success-text: var(--success-color);
}
