@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--quaternary-text-color);
  text-align: center;
}
