@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.number {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--active {
    background-color: var(--primary-color);
  }

  &--default {
    background-color: var(--accent-color);
  }

  &__text {
    font-weight: 500;
    font-size: 0.7rem;
    color: var(--color-white);
  }
}

.label {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--color-white);
  margin-left: 0.5rem;

  &--active {
    color: var(--primary-color);
  }

  &--default {
    color: var(--accent-color);
  }
}
