@import 'libs/styles/src/common';

.welcome-message {
  font-weight: 600;
  font-size: 1.125rem;

  @include media-breakpoint-down(xl) {
    display: none;
  }
}
