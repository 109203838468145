@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 75px;
}
