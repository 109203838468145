@import 'libs/styles/src/common';

.container {
  font-size: 0.75rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-inline: auto;
  width: fit-content;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
    text-align: center;
  }
}

.link {
  color: var(--secondary-color);
}
