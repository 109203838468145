@import 'libs/styles/src/common';

.container {
  display: flex;
  justify-content: space-between;
}

.label {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: var(--quaternary-text-color);
  margin-right: 10px;
  cursor: pointer;
}

.input {
  display: none;
}

.slider {
  position: relative;
  width: 42px;
  height: 24px;
  background-color: var(--background-light-grey);
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-white);
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.input:checked + .slider {
  background-color: var(--primary-color);
}

.input:checked + .slider::before {
  transform: translateX(18px);
}
