@import 'libs/styles/src/common';

.container {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 65px;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: var(--success-color);
  margin-top: 15px;
}
