@import 'libs/styles/src/common';

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 1.25rem;
}

.dropdown__container {
  margin-right: 20px;
}
