.container {
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  .text {
    padding-left: 0.5rem;
  }
}
